<template>
    <div v-for="dropdown in dropdowns" :key="dropdown"
        class="dropdown"
        :class="{ 'chat' : dropdown.name == 'CHAT'}">

        <!-- Boton para abrir el dropdown -->
        <button :ref="'notificationButton_' + dropdown.name"
            class="userButton"
            @click="NotificationButtonHandler(dropdown)"
            data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            
            <div v-if="dropdown.notificationsCount > 0" class="notification">
                {{ dropdown.notificationsCount }}
            </div>

            <i :class="{'bi bi-chat-dots-fill' : dropdown.name == 'CHAT', 'bi bi-bell-fill' : dropdown.name == 'OTHERS'}"
                :style="{ 'margin-right': dropdown.notificationsCount === 0 ? '0' : '' }"></i>
        </button>

        <div class="dropdown-menu notificationMenu">
            <!-- Boton de regreso a centro de notificaciones, titulo de la categoria y boton de marcar como leidas -->
            <div>
                <div  class="title">               
                    <button v-if="dropdown.name != 'CHAT' && selectedCategoryPK != null" class="iconButton" @click="BackToCategoriesClickHandler">
                        <i class="bi bi-arrow-left"></i>
                    </button>

                    <b>{{ dropdown.name == 'CHAT' ? 'CONVERSACIONES' : selectedCategoryPK == null ? 'CENTRO DE NOTIFICACIONES' :
                        notificationCategories.find(x => x.PKNotificationCategory == selectedCategoryPK).DisplayText }}</b>

                    <button v-if="dropdown.name == 'CHAT' || selectedCategoryPK != null" class="iconButton clearNotifications" style="margin-left: auto" @click="ClearNotificationsByCategory(dropdown)">
                        <i class="bi bi-envelope-open-fill"></i>
                    </button>
                </div>

                <!-- Listado de categorias de notificaciones (no aplica para chat) -->
                <div v-if="dropdown.name !== 'CHAT'" :style="{ display: selectedCategoryPK == null ? '' : 'none' }">
                    <div v-for="category in notificationCategories.filter(x => x.Category != 'CHAT')" :key="category.PKNotificationCategory"
                        class="notificationCategoryButton" @click="ChangeNotificationCategory(category)">
                            <i :class="category.IconClass"></i>
                            {{ category.DisplayText }}
                            <div class="unreadNotificationsCount">
                            <b>{{ category.NotificationsCount }}</b> 
                            </div>
                    </div>
                </div>

                <!-- Filtro de tipos de categorias -->
                <div :style="{ display: dropdown.name != 'CHAT' && selectedCategoryPK == null ? 'none' : '' }">
                    <div v-for="category in notificationCategories" :key="category.PKNotificationCategory"
                        class="summary"
                        :style="{ display: category.PKNotificationCategory == selectedCategoryPK ? '' : 'none' }">

                        <div class="summaryTitle">
                            RESUMEN DE ACTUALIZACIONES
                        </div>

                        <div class="notificationTypeWrapper">
                            <div v-for="notificationType in category.NotificationTypes" :key="notificationType.PKNotificationType"
                                class="notificationType"
                                :class="{ 'selected' : notificationType.PKNotificationType == category.selectedTypePK }"
                                @click="NotificationTypeChange(category.PKNotificationCategory, notificationType.PKNotificationType)">
                                
                                <div class="notificationIcon">
                                    <i :class="notificationType.IconName"></i>
                                </div>

                                {{ notificationType.Count + ' ' + notificationType.FilterText }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Notificaciones -->
            <div v-for="notificationCategory in (dropdown.name == 'CHAT' ? notificationCategories.filter(x => x.Category === 'CHAT') : notificationCategories.filter(x => x.Category !== 'CHAT'))"
                :id="'notificationCategory_' + notificationCategory.PKNotificationCategory"
                :key="notificationCategory.PKNotificationCategory"
                :style="{ display: dropdown.name == 'CHAT' || selectedCategoryPK == notificationCategory.PKNotificationCategory ? '' : 'none' }"
                class="notificationsWrapper">

                <NotificationCard v-for="notification in notificationCategory.selectedTypePK == null ?
                    notificationCategory.notifications :
                    notificationCategory.NotificationTypes.find(x => x.PKNotificationType == notificationCategory.selectedTypePK).notifications"

                    :key="notification" 
                    :notification="notification"
                    :userData="store.loadedUsersData[notification.FKUser]"
                    @click="ClickHandler(notificationCategory, notification)"/>

                <div class="notificationLoaderWrapper"
                    :style="{ display: dropdown.name == 'CHAT' ||
                        selectedCategoryPK == notificationCategory.PKNotificationCategory ? 'flex' : 'none' }">

                    <div :ref="'notificationLoader_' + notificationCategory.PKNotificationCategory" class="notificationLoader"></div>
                    <div v-if="notificationCategory.loading
                        || (notificationCategory.selectedTypePK == null && !notificationCategory.endOfNotifications)
                        || (notificationCategory.selectedTypePK != null && !notificationCategory.NotificationTypes.find(x => x.PKNotificationType == notificationCategory.selectedTypePK).endOfNotifications) " class="spinner-border text-light"></div>

                    <div v-if="(notificationCategory.selectedTypePK == null && !notificationCategory.loading && notificationCategory.endOfNotifications)
                            || (notificationCategory.selectedTypePK != null
                                && !notificationCategory.NotificationTypes.find(x => x.PKNotificationType == notificationCategory.selectedTypePK).loading
                                && notificationCategory.NotificationTypes.find(x => x.PKNotificationType == notificationCategory.selectedTypePK).endOfNotifications)"
                        class="noContentLabel">
                        <label>{{ notificationCategory.notifications.length > 0 ? 'FIN DE LAS NOTIFICACIONES' : 'NO HAY NOTIFICACIONES PARA MOSTRAR' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationService from '@/services/NotificationService.js';
import UserService from '@/services/UserService.js';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/DefaultStore';
import { collection, onSnapshot, orderBy, query, limit } from "firebase/firestore"; 
import { db } from '@/authentication/firebase.js'
import { onUnmounted } from 'vue';
import { inject } from 'vue';
import PromiseCallerService from '@/utilities/PromiseCallerService';
import NotificationCard from '@/components/Generic/NotificationCard.vue';

export default{
    data(){
        return{
            firstNotification: true,
            firstMessageNotification: true,

            users: [],
            notificationCategories: [],

            selectedCategoryPK: null,
            lastOtherSelectedCategoryPKPK: null,
            chatCategoryPK: null,

            dropdowns: [{
                name: 'CHAT',
                notificationsCount: 0
            },
            {
                name: 'OTHERS',
                notificationsCount: 0
            }]
        }
    },

    components: {
        NotificationCard
    },

    setup(){
        const router = useRouter();
        const store = useStore();
        const ShowNotification = inject('ShowNotification');
        const RefreshFilePage = inject('RefreshFilePage');

        return{
            router, store, ShowNotification, RefreshFilePage
        }
    },

    async mounted(){
        this.GetNotificationCategories();

        //Cuando se recibe una nueva notificación
        let channel = 'organizationsNotificationsUpdate_' + this.store.CurrentOrganizationPK;
        if(this.store.env != 'PRD')
            channel = this.store.env + '_' + channel;

        const q = query(
            collection(db, channel),
            orderBy('PKNotification', 'desc'),
            limit(1)
        );

        const unsub = onSnapshot(q, snapshot => {
            if(this.firstNotification){
                this.firstNotification = false;
                return;
            }

            let PKNotification = snapshot.docs[0].data().PKNotification;

            //Se obtienen los detalles de la notificación
            NotificationService.GetNotification({PKNotification})
            .then(async data => {
                let notification = data.Notification;

               //Si la notificacion no esta destinada para el usuario
               if(notification == null){
                    return;
                }

                //Se obtiene la información del usuario
                await this.GetNewUsersData([notification]);
                
                let category = this.notificationCategories.find(x => x.PKNotificationCategory == data.Notification.PKNotificationCategory);
                let allCategory = this.notificationCategories.find(x => x.Category == 'ALL');

                //Agrega la notificación a la lista de notificaciones de esa categoria
                category.notifications.unshift(notification);
                //Agrega la notificacion a la lista de todas
                allCategory.notifications.unshift(notification);

                //Se agrega 1 al coteo de notificaciones de esa categoría
                category.NotificationsCount++;

                //se agrega 1 al conteo de ALL (solo si la notificacion no es de tipo chat)
                if(data.Notification.Category != 'CHAT'){
                    this.notificationCategories.find(x => x.Category == 'ALL').NotificationsCount++;
                }

                //Se agrega 1 al conteo de notificaciones del dropdown
                let categoryName = data.Notification.Category == 'CHAT' ? 'CHAT' : 'OTHERS';
                this.dropdowns.find(x => x.name == categoryName).notificationsCount++;

                //se agrega uno al filtro del tipo de notificacion
                let notificationType = category.NotificationTypes.find(x => x.PKNotificationType == data.Notification.PKNotificationType);
                notificationType.Count++;

                //Se agrega la notificacion al listado de notificaciones del tipo de notificacion
                notificationType.notifications.unshift(notification);
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR CARGANDO UNA ACTUALIZACIÓN')
            })
        });

        onUnmounted(unsub);

        // Cuando se obtienen nuevos mensajes
        let msgsChannel = 'newMessageNotificationsUpdate_' + this.store.CurrentOrganizationPK;
        if(this.store.env != 'PRD')
            msgsChannel = this.store.env + '_' + msgsChannel;

        const messagesQuery = query(
            collection(db, msgsChannel),
            orderBy('PKMessage', 'desc'),
            limit(1)
        );

        const messagesUnsub = onSnapshot(messagesQuery, async snapshot => {
            if(this.firstMessageNotification){
                this.firstMessageNotification = false;
                return;
            }

            let PKMessage = snapshot.docs[0].data().PKMessage;

            //Obtiene el expediente y usuario que envío el mensaje
            NotificationService.GetNotification({PKNotification : 'M' + PKMessage})
            .then(async data => {
                if(data.Notification == null){
                    return;
                }

                //Si el usuario que envió el mensaje es el mismo que el usuario logueado, no se agrega la notificación
                if(data.Notification.FKUser == this.store.LoggedUser.PKUser){
                    return;
                }

                //Si no se desea recibir notificaciones de ese expediente, no se agrega la notificación
                if(this.store.unpinnedFiles.includes(data.Notification.PKFile)){
                    return;
                }

                //Si el usuario se encuentra en la página de detalle de expediente, no se agrega la notificación
                let currentRoute = this.$router.currentRoute.value;
                if(currentRoute.name == 'fileDetail' && currentRoute.params.PKFile == data.Notification.PKFile){
                    return;
                }

                this.RefreshMessageNotifications();
                return;
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR CARGANDO UNA ACTUALIZACIÓN')
            })

        });

        onUnmounted(messagesUnsub);
    },

    beforeUnmount(){
        this.ClearObservers();
    },

    methods: {
        BackToCategoriesClickHandler(){
            this.selectedCategoryPK = null;
            this.lastOtherSelectedCategoryPKPK = null;
        },

        NotificationTypeChange(PKNotificationCategory, PKNotificationType)
        {
            let category = this.notificationCategories.find(x => x.PKNotificationCategory == PKNotificationCategory);
            category.selectedTypePK = PKNotificationType == category.selectedTypePK ? null : PKNotificationType;
            this.ResetCategoryObserver(category);
            
            //Regresa el oversroll y al tope
            let element = document.getElementById('notificationCategory_' + PKNotificationCategory);
            element.scrollTop = 0;
        },

        RefreshMessageNotifications()
        {
            let category = this.notificationCategories.find(x => x.Category == 'CHAT');
            category.notifications = [];
            category.endOfNotifications = false;
            category.loading = false;
            category.PKLastNotification = null;
            this.dropdowns.find(x => x.name == 'CHAT').notificationsCount++;

            let notificationType = category.NotificationTypes.find(x => x.NotificationType == 'SentMessage');
            notificationType.notifications = [];
            notificationType.endOfNotifications = false;
            notificationType.loading = false;

            this.ResetCategoryObserver(category);
        },

        ResetCategoryObserver(category)
        {
            category.loaderObserver.disconnect();
            this.ObserveFunction(category);
        },

        ClearObservers(){
            this.notificationCategories.forEach(category => {
                category.loaderObserver.disconnect();
            })
        },

        ObserveFunction(category)
        {
            const options = {
                rootMargin: '0px',
                threshold: 0.5
            };

            let targetElement = this.$refs['notificationLoader_' + category.PKNotificationCategory][0];
            let ogCategory = category;

            category.loaderObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if(category.selectedTypePK != null){
                        let notificationType = category.NotificationTypes.find(x => x.PKNotificationType == category.selectedTypePK);
                        category = notificationType;
                    }

                    if (entry.isIntersecting && !category.loading && !category.endOfNotifications){
                        category.loading = true;

                        //Loads notifications cargara las notificaciones y aplicara los filtros necesarios en la busqueda
                        this.LoadNotifications(ogCategory)
                        .then(()=>{
                            category.loading = false;

                            ogCategory.loaderObserver.unobserve(targetElement);
                            setTimeout(()=>{
                                ogCategory.loaderObserver.observe(targetElement);
                            }, 1500);
                        })
                        .catch(error => {
                            category.loading = false;
                            this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LAS NOTIFICACIONES');
                        })
                    }
                });
            }, options);

            category.loaderObserver.observe(targetElement);
        },

        SetObservers()
        {
            this.notificationCategories.forEach(category => {
                this.ObserveFunction(category)
            });
        },

        ChangeNotificationCategory(category){
            this.selectedCategoryPK = category.PKNotificationCategory;
            this.lastOtherSelectedCategoryPKPK = category.PKNotificationCategory;
        },

        async ClearNotificationsByCategory(dropdown)
        {
            let auxCategoryPK =  dropdown.name == 'CHAT' ? this.chatCategoryPK : this.selectedCategoryPK;

            let data = {
                PKNotificationCategory: auxCategoryPK
            };

            this.store.SetLoader(true);
            await PromiseCallerService.CallAsPromise(NotificationService.ClearNotificationsByCategory, data)
            .then(data => {
                //Data regresa el nuevo conteo de notificaciones
                let category = this.notificationCategories.find(x => x.PKNotificationCategory == auxCategoryPK);

                if(category.Category == 'ALL'){
                    // Se marca IsRead todas las notificaciones de las categorías que no sean chat
                    this.notificationCategories.filter(x => x.Category != 'CHAT').forEach(x => { 
                        x.notifications.map(x => x.IsRead = true);
                        x.NotificationsCount = 0;
                    });

                    // Se limpian las notificaciones sin leer de los diferentes tipos de notificaciones de cada categoria
                    this.notificationCategories.forEach(x => x.NotificationTypes.forEach(y => {
                        y.notifications.map(z => z.IsRead = true);
                        y.Count = 0;
                    }));
                }
                else{
                    // Se marcan como leidas las notificaciones de la cateogria
                    category.notifications.filter(x => !x.IsRead).forEach(x => x.IsRead = true);

                    if(category.Category != 'CHAT'){
                        // Se marcan como leidas las notificaciones de la categoria dentro de ALL
                        let allCategory = this.notificationCategories.find(x => x.Category == 'ALL');
                        let notificationsToSetAsReadInAll = allCategory.notifications.filter(x => category.notifications.some(y => y.PKNotification == x.PKNotification));
                        notificationsToSetAsReadInAll.forEach(x => x.IsRead = true);
                    }
                    
                    // Se limpian las notificaciones sin leer de la categoria
                    category.NotificationTypes.forEach(x => {
                        x.notifications.map(x => x.IsRead = true);
                        x.Count = 0;
                    });
                }

                //Se recalcula la cantidad de notificaciones por categoria
                data.newNotificationsCount.forEach(x => {
                    let category = this.notificationCategories.find(y => y.PKNotificationCategory == x.PKNotificationCategory);
                    category.NotificationsCount = x.Count;
                });
                
                this.ShowNotification('success', 'LAS NOTIFICACIONES HAN SIDO MARCADAS COMO LEIDAS EXITOSAMENTE');
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL MARCAR LAS NOTIFICACIONES COMO LEIDAS');
            })
            this.store.SetLoader(false);
        },

        GetNotificationCategories(){
            PromiseCallerService.CallAsPromise(NotificationService.GetNotificationCategories)
            .then(data => {
                data.NotificationCategories.forEach(category => {
                    category.notifications = [];
                    category.endOfNotifications = false;
                    category.loading = false;
                    category.PKLastNotification = null;
                    category.loaderObserver = null;
                    category.selectedTypePK = null;
                    
                    category.NotificationTypes.forEach(x => {
                        x.notifications = [];
                        x.endOfNotifications = false;
                        x.loading = false;
                        x.PKLastNotification = null;
                    });
                });

                this.notificationCategories = data.NotificationCategories;


                this.dropdowns.find(x => x.name == 'OTHERS').notificationsCount = this.notificationCategories.find(x => x.Category == 'ALL').NotificationsCount;
                this.dropdowns.find(x => x.name == 'CHAT').notificationsCount = this.notificationCategories.find(x => x.Category == 'CHAT').NotificationsCount;

                this.chatCategoryPK = this.notificationCategories.find(x => x.Category == 'CHAT').PKNotificationCategory;

                this.$nextTick(() => {
                    this.SetObservers();
                });
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LAS CATEGORÍAS DE NOTIFICACIONES');
            })
        },

        // Category = CATEGORIA PADRE (PUEDE SER CHAT O ALL)
        ClickHandler(category, notification) {
            if(!notification.IsRead){
                // Obtiene las categorias
                let allCategory = this.notificationCategories.find(x => x.Category == 'ALL');
                let notificationCategory = this.notificationCategories.find(x => x.PKNotificationCategory == notification.PKNotificationCategory);
                let notificationType = notificationCategory.NotificationTypes.find(x => x.PKNotificationType == notification.PKNotificationType);

                let allNotifications = [];
                let categoryNotifications = [];
                let typeNotifications = [];

                let isConversationNotification = notification.PKNotification == 0;

                // Si es de tipo conversación, se marcan leidas por FKFile (porque no tienen PKNotification)
                if(isConversationNotification){
                    categoryNotifications = category.notifications.filter(x => x.PKFile == notification.PKFile && x.PKNotificationType == notification.PKNotificationType);
                    typeNotifications = notificationType.notifications.filter(x => x.PKFile == notification.PKFile);
                }
                // Si no, se marcan leidas por PKNotification
                else{
                    allNotifications = allCategory.notifications.filter(x => x.PKNotification == notification.PKNotification);
                    categoryNotifications = notificationCategory.notifications.filter(x => x.PKNotification == notification.PKNotification);
                    typeNotifications = notificationType.notifications.filter(x => x.PKNotification == notification.PKNotification);
                }
                // Se marcan las notificaciones como leidas en ALL, Category y Type
                let notificationsToSetAsRead = categoryNotifications.concat(typeNotifications).concat(allNotifications);
                notificationsToSetAsRead.forEach(x => x.IsRead = true);

                // Si no es mensaje, se resta de ALL
                if(!isConversationNotification){
                    allCategory.NotificationsCount--;
                }

                notificationCategory.NotificationsCount--;
                notificationType.Count--;

                //Se marca la notificación como leida en la BD
                if(notification.PKNotification != 0){
                    NotificationService.SetNotificationRead({PKNotification: notification.PKNotification})
                    .catch(error =>{
                        this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL MARCAR LA NOTIFICACIÓN COMO LEÍDA');
                    });
                }
            }

            //Closes the notification dropdown in mobile when is clicked
            if(category.Category == 'CHAT'){
                this.$refs['notificationButton_CHAT'][0].click();
            }
            else{
                this.$refs['notificationButton_OTHERS'][0].click();
            }

            //Se redirige a la página de detalle del expediente de la notificación
            const PKCurrentFile = this.$route.params.PKFile;
            if(PKCurrentFile != notification.PKFile){
                this.store.notificationRedirectPK = notification.PKFile;
                this.router.push({ name: 'fileDetail', params: { PKFile: notification.PKFile }});

                this.$nextTick(() => {
                    this.RefreshFilePage();
                });
            }
        },

        NotificationButtonHandler(dropdown){
            this.selectedCategoryPK = dropdown.name == 'CHAT' ? this.chatCategoryPK : this.lastOtherSelectedCategoryPKPK;
            dropdown.notificationsCount = 0;
        },

        LoadNotifications(category){
            return new Promise((resolve, reject) => {

                let data = {};
                let isChat = false;

                if(category.selectedTypePK != null){
                    data.PKNotificationCategory = category.PKNotificationCategory;
                    category = category.NotificationTypes.find(x => x.PKNotificationType == category.selectedTypePK);
                    data.PKNotificationType = category.PKNotificationType;
                    data.PKLastNotification = category.PKLastNotification;

                    isChat = category.NotificationType == 'SentMessage';
                    if(isChat)
                        data.rowNumber = category.notifications.length;
                }
                else{
                    data.PKNotificationCategory = category.PKNotificationCategory;
                    data.PKLastNotification = category.PKLastNotification;
                    data.rowNumber = category.notifications.length;

                    isChat = category.Category == 'CHAT';
                }

                let func = isChat ? NotificationService.GetMessagesNotifications : NotificationService.GetNotificationsByCategory;

                func(data)
                .then(async data => {
                    let notifications = data.Notifications;
                    category.endOfNotifications = notifications.length < 25;

                    let removeExistingNotifications = !isChat && category.PKLastNotification == null;

                    if(data.Notifications.length > 0){
                        if(!isChat)
                            category.PKLastNotification = data.Notifications[data.Notifications.length - 1].PKNotification;
                        else{
                            //Obtiene las notificaciones que NO TENGAN PKNotification = 0
                            let notificationsToSetAsRead = data.Notifications.filter(x => x.PKNotification != 0);

                            //Obtiene el menor PKNotification de esos
                            let PKLastNotification = Math.min(...notificationsToSetAsRead.map(x => x.PKNotification));

                            //Se actualiza el PKLastNotification
                            if(isFinite(PKLastNotification))
                                category.PKLastNotification = PKLastNotification;

                            //Se actualiza la cantidad de unread conversations type
                            if(category.NotificationTypes){
                                let sentMessageCategory = category.NotificationTypes.find(x => x.NotificationType == 'SentMessage');
                                sentMessageCategory.Count = data.UnreadMessagesCount;
                            }
                            else{
                                category.Count = data.UnreadMessagesCount;
                            }
                        }
                    }

                    await this.GetNewUsersData(notifications);

                    if(removeExistingNotifications){
                        category.notifications = [];
                    }
                    
                    category.notifications = category.notifications.concat(notifications);
                    category.loading = false;

                    resolve();
                })
                .catch(error => {
                    category.loading = false;
                    reject(error);
                })
            })
        },

        GetNewUsersData(newNotifications) {
            return new Promise((resolve, reject) => {
                //getting the distintc senders name and profile picture
                let usersPKs = [];
                    
                //get the FK of the sender in the new messages
                usersPKs = [...new Set(newNotifications.map(x => x.FKUser))];

                //remove those who are already in the users array
                usersPKs = usersPKs.filter(x => !this.users[x]);

                if(usersPKs.length == 0){
                    resolve();
                    return;
                }

                //In case a different user sent a message gets it profile picture
                this.GetUsersMessageData(usersPKs)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
            });
        },

        GetUsersMessageData(usersPKs) {
            return new Promise((resolve, reject) => {
                UserService.GetUsersMessageData({ usersPKs: usersPKs })
                .then(data => {
                    data.Users.forEach(user => {
                        this.store.loadedUsersData[user.PKUser] = user;
                        this.users[user.PKUser] = user;
                    });

                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
            });
        }
    }
}
</script>

<style scoped>
@media screen and (min-width: 600px){
}
/* Mobile */
@media screen and (max-width: 600px){
    .notificationMenu {
        max-height: calc(100vh - 54px);
        max-width: 100vw;
    }

    .userButton {
        margin-right: 0px;
    }
}

.clearNotifications:hover {
    background-color: var(--LativoDarkPurpleHover);
}

.clearNotifications i {
    font-size: 16px !important;
    margin-right: 0px !important;
}

.summary {
    padding: 10px;
    padding-top: 0px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.summaryTitle {
    color: lightgray;
    margin: 5px;
}

.notificationTypeWrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    cursor: pointer;
}

.notificationType {
    display: flex;
    align-items: center;
    background-color: var(--LativoDarkPurpleHover);
    border-radius: 25px;
    color: white;
    padding: 5px;
    margin: 5px;
    margin-right: 10px;
    font-size: 12px !important;
}

.notificationType:hover {
    background-color: rgb(76, 76, 96);
}

.notificationType.selected {
    background-color: var(--LativoLightPurple);
    color: var(--LativoDarkPurple);
    font-weight: bold;
}

.notificationType.selected i {
    color: white;
}

.notificationType .notificationIcon {
    background-color: var(--Notification);
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.notificationCategoryButton {
    color: white;
    width: 100%;
    height: 40px;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.notificationCategoryButton:hover {
    background-color: var(--LativoDarkPurpleHover);
}

.notificationCategoryButton i {
    margin-right: 10px;
}

.notificationCategoryButton .unreadNotificationsCount {
    margin-left: auto;
}

.notificationCategoryButton .unreadNotificationsCount b {
    color: var(--Notification);
}

.dropdown {
    max-width: 200px;
}

.dropdown.chat {
    margin-left: auto;
}

.notificationsWrapper {
    max-height: 450px;
    overflow-y: auto;
}

.title {
    margin: 5px;
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.title > button > i {
    color: white;
    margin-right: 10px;
}

.notificationMenu{
    position: sticky;
    z-index: 9999; 
    width: 450px;
    height: auto;
    background-color: var(--LativoDarkPurple);
    border-radius: 10px;
    font-size:14px !important;
    z-index: 9000 !important;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.notificationMenu b{
    color: #ffffff;
}

.notification{
    border-radius: 50%; 
    background-color: red;
    color: white;
    right: 5px;
    top: 2px;
    position: absolute;
    z-index: 100;
    min-width: 16px;
    min-height: 16px;
    max-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.notificationLoaderWrapper {
    width: 100%; display: flex; justify-content: center; margin-top: 15px; margin-bottom: 15px
}

.notificationLoaderWrapper .notificationLoader {
    display: block; width: 1px; height: 1px; background-color: transparent;
}

.notificationLoaderWrapper .noContentLabel {
    margin-top: 10px;  margin-bottom: 15px
}

.noContentLabel label {
    color: lightgray;
}
</style>