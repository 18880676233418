<template>
    <cropper
        :key="image.src"
        ref="cropperRef"
        class="cropper"
        :class="id"
        :stencil-component="CircleStencil"
        :src="image.src"
        :autoZoom="false"
        :imageRestriction="'none'"/>
</template>
  
<script>
import { defineComponent, ref, reactive, onUnmounted } from "vue";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
    name: "AdvancedCropper",
    components: {
        Cropper,
    },

    props: {
        id: {
            type: String,
            required: false
        }
    },

    setup() {
        const cropperRef = ref();
        const fileRef = ref();

        const image = reactive({
        });

        onUnmounted(() => {
            // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
            if (image.src) {
                URL.revokeObjectURL(image.src);
            }
        });

        return {
            CircleStencil,
            cropperRef,
            fileRef,
            image,

            cropImage() {
                return new Promise((resolve, reject) => {
                    const { canvas } = cropperRef.value.getResult();

                    if (canvas) {
                        canvas.toBlob(blob => {
                            resolve(blob);
                        }, 'image/png');
                    }
                    else {
                        reject(new Error("Canvas no encontrado."));
                    }
                });
            },

            getCroppedImage() {
                return new Promise((resolve, reject) => {
                    const cropper = this.$refs.cropperRef;
                    const { canvas } = cropper.getResult();

                    if (canvas) {
                        canvas.toBlob((blob) => {
                            // Crear un objeto File desde el blob
                            const file = new File([blob], "image.png", { type: 'image/png' });

                            // Resolver la promesa con el objeto File
                            resolve(file);
                        }, 'image/png');
                    }
                    else {
                        reject();
                    }
                })
            },

            uploadImage(file, isUrl = false) {
                if(!file){
                    this.image = null;
                }
                
                if (image.src) {
                    URL.revokeObjectURL(image.src);
                }
                
                image.type = 'image/png';

                if(isUrl){
                    image.src = file;
                }
                else{
                    const blob = URL.createObjectURL(file);
                    image.src = blob;
                }
            }
        };
    },
});
</script>
  
<style scoped>

.cropper-container {
  position: relative;
  overflow: hidden;
}

.cropper {
  width: 100%;
  height: auto;
  min-height: 465px;
}
</style>
  