import SessionHandler from "@/authentication/SessionHandler.js";
import { useStore } from '@/store/DefaultStore.js';

const urls = {
    DEV: 'http://localhost:80/api/',
    STG: 'https://api.freesoft.com.mx/api/',
    PRD: 'https://api.lativo.com.mx/api/'
}

async function Post(url, data = {}){
    const store = useStore();
    let baseUrl = urls[store.env];
    url = baseUrl + url;

    let token;
    await SessionHandler.GetAccessToken()
    .then(data => {
        token = data;
    })
    .catch(error => {
        throw new Error(error.message)
    })


    if(store.CurrentOrganizationPK != null)
        data['CurrentOrganizationPK'] = store.CurrentOrganizationPK;

    url += '?' + new URLSearchParams(data).toString();

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    })
    .then(response => {
        if (!response.ok) {
            return response.json().then(data => {
                throw new Error(data.message);
            });
        }
        return response.json();
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(error.message)
    });
}

async function Get(url, data = {}){
    const store = useStore();
    let baseUrl = urls[store.env];
    url = baseUrl + url;

    let token;
    await SessionHandler.GetAccessToken()
    .then(data => {
        token = data;
    })
    .catch(error => {
        throw new Error(error.message)
    })

    if(store.CurrentOrganizationPK != null)
        data['CurrentOrganizationPK'] = store.CurrentOrganizationPK;

    url += '?' + new URLSearchParams(data).toString();

    let headers = {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + token
    };

    return fetch(url, {
        method: 'GET',
        headers: headers
    })
    .then(response => {
        if (!response.ok) {
            return response.json().then(data => {
                throw new Error(data.message);
            });
        }
        return response.json();
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(error.message)
    });
}

async function PostFormData(url, data = {}, files = []) {
    const store = useStore();
    let baseUrl = urls[store.env];
    url = baseUrl + url;

    let token;
    await SessionHandler.GetAccessToken()
    .then(data => {
        token = data;
    })
    .catch(error => {
        throw new Error(error.message);
    })

    // Crea una instancia de FormData
    let formData = new FormData();

    // Agrega los datos al FormData
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    //Agrega los archivos al FormData
    for (let [key, value] of files.entries()) {
        formData.append(key, value);
    }

    // Si la organización actual está presente, agrégala
    if (store.CurrentOrganizationPK != null) {
        formData.append('CurrentOrganizationPK', store.CurrentOrganizationPK);
    }

    return fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    .then(response => {
        if (!response.ok) {
            return response.json().then(data => {
                throw new Error(data.message);
            });
        }
        return response.json();
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(error.message);
    });
}

export default {
    Post, Get, PostFormData
}

