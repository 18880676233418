<template>
  <main>
    <LoadingScreen />
    <NavBar :key="navbarKey" v-if="store.LoggedUser != null && !store.isLoginOrRegister"/>
    <RouterView :key="fileKey"/>
  </main>
</template>

<script>
import { sendPasswordResetEmail, onAuthStateChanged, getAuth } from 'firebase/auth';
import NavBar from './components/Generic/Navbar.vue'
import { RouterView } from 'vue-router'
import LoadingScreen from './components/Generic/Loading.vue'
import { useStore } from '@/store/DefaultStore.js';
import { provide } from 'vue';
import { useToast } from 'vue-toastification';
import NotificationService from './services/NotificationService.js';
import AuthenticationService from './services/AuthenticationService';
import UserService from './services/UserService.js';
import { useRouter } from 'vue-router';
import SessionHandler from '@/authentication/SessionHandler.js';
import { sendEmailVerification } from "firebase/auth";

export default {
  name: 'App',
  components: {
    NavBar, RouterView, LoadingScreen
  },
  data() {
    return {
      array: [],
      fileKey: 0,
      navbarKey: 0,

      signingUp: false,
      fields: ['txtEmail', 'txtPassword'],
      showQR: false,
      token: '',
      SecretKey: '',
      hasInstance: false,
      restoreMail: '',
      toLogUser: '',
      toLogUserEmail: '',
      showPassword: false,

      ShowNotificationBase : {
        'error': this.ShowErrorNotification,
        'success': this.ShowSuccessNotification,
      },

      firebaseErrors: {
        'auth/invalid-email': 'El correo electrónico no es válido.',
        'auth/user-not-found': 'El correo electrónico no está registrado.',
        'auth/wrong-password': 'La contraseña es incorrecta.',
        'auth/email-already-in-use': 'El correo electrónico ya está en uso.',
        'auth/weak-password': 'La contraseña debe tener al menos 6 caracteres.',
        'auth/too-many-requests': 'Demasiados intentos de inicio de sesión fallidos. Inténtelo de nuevo más tarde.',
        'auth/network-request-failed': 'No hay conexión a internet.',
        'auth/invalid-verification-code': 'El código de verificación no es válido.',
        'auth/invalid-verification-id': 'El código de verificación no es válido.',
        'auth/invalid-credential': 'El código de verificación no es válido.',
        'auth/credential-already-in-use': 'El código de verificación ya está en uso.'
      } 
    }
  },

  setup() {
    const store = useStore();
    const toast = useToast();
    const speakeasy = require('speakeasy');
    const qrcode = require('qrcode');
    const router = useRouter();

    const desktopMediaQuery = window.matchMedia('(min-width: 600px)');
    store.lessThan600 = !desktopMediaQuery.matches;

    desktopMediaQuery.addEventListener('change', (e) => {
      store.lessThan600 = !e.matches;
      document.body.style.overflow = store.lessThan600 ? 'auto' : 'hidden';
    });

    return {
      store, toast, speakeasy, qrcode, router
    }
  },

  mounted() {
    this.store.isMobile = this.isMobileDevice() || this.isTabletDevice();

    this.auth = getAuth();

    onAuthStateChanged(this.auth, async user => {
      if(!user){
        return;
      }

      await SessionHandler.AutoRefreshToken(user)
      .catch(() => {
        this.router.push({ name: 'login' });
      });
    });

    this.onResize();
    window.addEventListener('resize', this.onResize);

    provide('ShowNotification', this.ShowNotification);
    provide('GetFileIcon', this.GetFileIcon);
    provide('GetFileIconColor', this.GetFileIconColor);
    provide('FormatDate', this.FormatDate);
    provide('ReadBase64File', this.ReadBase64File);
    provide('ConvertFileToBase64', this.ConvertFileToBase64);
    provide('ConvertDate', this.ConvertDate);
    provide('ShowNotificationBase', this.ShowNotificationBase);
    provide('RefreshFilePage', this.RefreshFilePage);
    provide('FormatSpanishDate', this.FormatSpanishDate);
    provide('ChangeOrganization', this.ChangeOrganization);
    provide('GetStrHour', this.GetStrHour);
    provide('PostNotificationUpdate', this.PostNotificationUpdate);
    provide('NormalizeText', this.NormalizeText);
  },

  methods: {
    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Patrones para detectar los principales tipos de dispositivos móviles.
      return /android|avantgo|bada\/|blackberry|bb|cricket|docomo|elaine|htc|iemobile|iphone|ipod|iris|kindle|meego|minimo|mobile.+firefox|maemo|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|tf)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|t)|bumb|bmc|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|es(uc|sy)|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(|)|hp|hs)|hu(aw|tc)|i(20|go)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inq|ipaq|iris|ja(t|v)a|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|o)|le(no|xi)|lg( g|\/(3|h[1-2]|s|u)|lge )|libw|lynx|m1w|m3ga|m50\/|m5n|mc(01|21)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|ti|to)|mt(50|p1|p5|p6|po)|mv(01|03)|mwbp|mywa|n10[0-2]|n20[2-3]|n30[0-2]|n50[0-2]|n7[0-2]|nec|ne(w|wf|wg|n|n0|ni)|nok(4|6|9|c5|n|z)|o2im|op(ti|wv)|oran|owg1|p800|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qtek|r380|r600|raks|sanyo|sgh|sharp|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|cl|er|io|kc|nd)|softbank|sn49|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|20|30|60)|tc(l(5|6)||tt)|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m)|tx9|upg1|up\.b|ushi|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|vx(53|60|61|70|80|90)|vzwap|wa(ut|tc)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|xda(|c)|xda/i.test(userAgent.substr(0, 4));
    },

    isTabletDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Macintosh/i.test(userAgent) && navigator.maxTouchPoints > 0
    },

    PostNotificationUpdate(PKNotification, FKFile = null){
      let data = {
        PKNotification: PKNotification,
      }

      if(FKFile != null) {
        data.FKFile = FKFile;
      }

      NotificationService.PostNotificationUpdate(data, this.store.CurrentOrganizationPK)
      .catch(() => {
        this.ShowNotification('error', 'OCURRIÓ UN ERROR AL CREAR LA NOTIFICACIÓN DE LA ACCIÓN REALIZADA');
      })
    },

    async GetUserOrganizations() {
      return new Promise((resolve, reject) => {
        this.store.SetLoader(true);

        UserService.GetUserOrganizations()
          .then(data => {
            this.store.Organizations = data.Organizations;
            resolve();
          })
          .catch(error => {
            this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL OBTENER LAS ORGANIZACIONES DEL USUARIO');
            this.store.SetLoader(false);
            reject();
          })
      })
    },

    NormalizeText(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    ChangeOrganization(){
      this.$router.go(0);
    },

    AddNotification(notification){
      notification.FKUser = this.store.LoggedUser.PKUser;

      NotificationService.AddNotification(notification)
      .catch(() => {
        this.ShowNotification('error', 'OCURRIÓ UN ERROR CREANDO LA NOTIFICACIÓN')
      })
    },

    GetStrHour(date) {
      let hour = date.getHours();
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let period = hour >= 12 ? 'PM' : 'AM';

      if (hour === 0) {
          hour = 12;
      } else if (hour > 12) {
          hour -= 12;
      }

      hour = String(hour).padStart(2, '0');
      return `${hour}:${minutes} ${period}`;
    },

    RefreshFilePage(){
      this.fileKey++;
    },

    onResize() {
      let timeout = this.store.isIpad ? 500 : 0;

      setTimeout(() => {
        this.store.SetDeviceWidth(window.innerWidth);        
      }, timeout);
    },

    //Recieves date in object and returns new JS Date Object
    ConvertDate(date){
      return new Date(date.year, date.month-1, date.day, date.hour, date.minute, 0);
    },

    //Recieves date and formats it in a way PHP can parse it
    FormatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },

    FormatSpanishDate(stringDate) {
      const fechaHora = new Date(stringDate);

      const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };
      const fechaFormateada = fechaHora.toLocaleDateString('es-ES', opcionesFecha);

      const opcionesHora = { hour: '2-digit', minute: '2-digit' };
      const horaFormateada = fechaHora.toLocaleTimeString('es-ES', opcionesHora);

      const ampm = fechaHora.getHours() >= 12 ? 'PM' : 'AM';

      const resultado = `${fechaFormateada} ${horaFormateada} ${ampm}`;

      return resultado;
    },

    ShowSuccessNotification(message){
      this.toast.success(message, this.store.toastOptions);
    },

    ShowErrorNotification(message){
      this.toast.error(message, this.store.toastOptions);
    },

    ShowNotification(type, message) {
      let toasts = {
        'error': () => {
          this.toast.error(message, this.store.toastOptions);
        },
        'success': () => {
          this.toast.success(message, this.store.toastOptions);
        },
        'warning': () => {
          this.toast.warning(message, this.store.toastOptions);
        }
      }

      toasts[type]();
    },

    GetFileIcon(mimeType) {
      return (mimeType == 'application/pdf' || mimeType=='PDF') ? 'bi bi-file-earmark-pdf-fill' :
        (mimeType == 'image/png' || mimeType == 'image/jpeg' || mimeType=='Image') ? 'bi bi-image-fill' :
        'bi bi-file-earmark-word-fill';
    },

    GetFileIconColor(mimeType) {
      return (mimeType == 'application/pdf' || mimeType=='PDF') ? 'rgb(203, 6, 6)' :
        (mimeType == 'image/png' || mimeType == 'image/jpeg' || mimeType == 'Image') ? 'rgb(0, 0, 0)' :
        'rgb(41, 84, 151)';
    },

    ReadBase64File(content) {
      return `data:application/json;base64,${content}`;
    },

    ConvertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64Content = reader.result.split(',')[1];
           resolve(base64Content);
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    },

    //LOGIN METHODS
    SendRestore(){
      sendPasswordResetEmail(this.auth, this.restoreMail)
      .then(() => {
          this.ShowNotification('success', 'SE HA ENVIADO EL CORREO DE RECUPERACIÓN CORRECTAMENTE')
          this.restoreMail = '';
          this.$refs.btnCloseRestoreModel.click();
      })
      .catch((e) => {
          let firebaseMessage = this.firebaseErrors[e.code];
          this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
      })
    },

        CreateNewQR(){
            this.Secret = this.speakeasy.generateSecret({name: 'Lativo Token'});
            this.SecretKey = this.Secret.hex;
            let imageCode = '';
            this.qrcode.toDataURL(this.Secret.otpauth_url, function(err, image) {
                imageCode = image;
            })
            this.QRImage = imageCode;
            this.showImage = true;
            this.showQR = true;
        },

        RemoveMultifactorCaller(){
            this.$refs.confirmationModal.OpenModal(
                '¿ESTÁ SEGURO DE QUE DESEA RESTAURAR SU INSTANCIA DE GOOGLE AUTHENTICATOR? NO PODRÁ VOLVER A INGRESAR SIN UNA NUEVA INSTANCIA',
                false,
                this.RemoveMultifactor,
                null
            );
        },

        RemoveMultifactor(){
            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);

                AuthenticationService.RemoveSecretKey()
                .then(data => {
                    this.store.SetLoader(false);

                    if(!data.success){
                        this.ShowNotification('error', data.message ? data.message : 'OCURRIÓ UN ERROR AL RESTAURAR LA INSTANCIA DE GOOGLE AUTHENTICATOR');
                        resolve();
                        return;
                    }

                    this.hasInstance = false;
                    this.ShowNotification('success', 'LA INSTANCIA DE GOOGLE AUTHENTICATOR SE HA RESTAURADO CORRECTAMENTE');
                    this.CreateNewQR();
                    resolve();
                })
                .catch(() => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', 'OCURRIÓ UN ERROR AL RESTAURAR LA INSTANCIA DE GOOGLE AUTHENTICATOR');
                    reject();
                })
            })
        },

        ResendConfirmationMail(user){
            sendEmailVerification(user)
            this.ShowNotification('success', 'SE HA VUELTO A ENVIAR EL CORREO DE VERIFICACIÓN');
        },

        ValidateForm(){
            let valids = [];

            this.fields.forEach(field=>{
                valids.push(this.$refs[field].GetValid());
            });

            return (valids.includes(false) ? false : true);
        },
      }
}
</script>

<style>
/* Desktop */
@media (min-width: 600px){
  .pretorCard {
    height: calc(100vh - 60px - 30px);
    background-color: white; 
    margin: 15px; 
    border-radius: 15px; 
    box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.3); 
  }

  .pretorCard header {
    padding: 15px;
    align-content: center;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-top: 38px;
  }

  .pretorCardHeader {
    display: flex; width: 100%; align-items: baseline; padding: 15px;
  }

  .count {
    display: none;
  }

  .PretorHardHeadersContainer > *:not(:last-child) {
    margin-right: 10px;
  }
}
/* Mobile */
@media (max-width: 600px){
  .pretorCard {
    background-color: var(--LativoLightPurple) !important; 
    margin: 5px;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent;
    height: min-content;
    max-height: min-content;
    min-height: calc(100vh - 0px);
  }

  .headerElements {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .pretorCard header {
    display: block;
    background-color: var(--LativoLightPurple);
    position: sticky;
    z-index: 800;
    top: 60px;
  }

  .pretorCardHeader {
    position: sticky; top: 60px; background-color: var(--LativoLightPurple); z-index: 100; padding: 15px;
  }

  .pretorModal {
    max-height: 90vh !important;
  }
}

.required {
  color: red !important;
}

.required.requiredFields {
  margin-right: 3px;
}

.mobileButtonTooltipWrapper {
    position: sticky; right: 20px; bottom: 20px;
}

.PretorHardHeadersContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.pretorCardTitle {
  display: flex; align-items: center; margin-bottom: 5px;
}

.modal-header {
  color: gray;
}

button {
  touch-action: manipulation !important;
}

.pretorCard.iPad {
  height: calc(100vh - 60px - 105px);
}

.btn-close:focus {
  box-shadow: none;
}

.titleDiv {
    display: flex;
    align-items: center;
}

.titleDiv .count {
    margin-left: auto;
    color: gray;
}

.pretorCard .header .title{
  font-weight: bold !important;
  font-size: 16px !important;
  color: black !important;
  margin-bottom: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 20px;
}

.bi-check-circle-fill {
    color: green;
}

.bi-x-circle-fill {
    color: red;
}

.bi-file-earmark-pdf-fill {
  color: rgb(203, 6, 6)
}

.bi-image-fill{
  color: rgb(0, 0, 0)
}

.bi-file-earmark-word-fill{
  color: rgb(41, 84, 151)
}

.bi-info-circle{
  color: var(--Info);
}

i {
  margin-right: 5px;
}

#app-container {
  height: calc(100% - env(safe-area-inset-bottom));
  overflow: auto;
}

[disabled] {
  cursor: not-allowed;
}

.login{
  background: rgb(244,249,255);
  background: radial-gradient(circle at top -30px left 40%, rgba(244,249,255,1) 5%, rgba(127,130,145,1) 25%, rgba(48,48,58,1) 50%, rgba(31,31,44,1) 60%, rgba(17,17,19,1) 80%);
}

.fullWidth{
    width: 100%;
    height: 50px;
    border-radius: 0px;
    margin: 0px;
}

.mobile{
    padding: 0px;
}

.modal-body b{
  margin-right: 5px;
}

.info-paragraph{
  border-bottom: 1px solid lightgray;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.info{
  color: var(--Info);
  margin-right: 3px;
  font-size: 14px;
}

.iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    border-radius: 50%;
    border: 0px;
    height: 32px;
    width: 32px;
    margin: 0px;
}

.iconButton i {
    font-size: 20px !important;
    line-height: 0;
    margin-right: 0px;
}

.iconButton:hover {
    background-color: rgb(0, 0, 0, 0.05);
}

.underlinedText {
    text-decoration: underline;
    color: rgb(16, 110, 253);
    cursor: pointer;
}

.lativoTooltip .underlinedText {
  color: lightskyblue;
}

:root{
  --PretorGrey: rgb(30, 30, 30);
  --PretorLightGrey: rgb(63, 63, 75);
  --Info: rgb(58, 92, 212);
  --Notification: rgb(46, 137, 255);

  --LativoLightPurple: rgb(222, 228, 255);
  --LativoDarkPurple: rgb(31, 31, 44);
  --LativoDarkPurpleHover: rgb(56, 56, 76);
}

.mandatoryFieldLabel {
  color: red; margin-left: auto; margin-right: 3px;
}

@font-face {
  font-family: 'Synonym';
  src: url('../src/font/Synonym-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SynonymMedium';
  src: url('../src/font/Synonym-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SwitzerBold';
  src: url('../src/font/Switzer-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SwitzerMedium';
  src: url('../src/font/Switzer-Medium.otf') format('opentype');
}

.bodyBackground{
    background-color: rgb(245, 245, 245) !important;
}

body{
  margin: 0;
  font-family: 'Synonym', sans-serif; 
  background-color: var(--LativoLightPurple) !important;
}

.input-with-icon {
    position: relative;
}

.input-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-right: 0px;
    transform: translateY(-50%);
    color: gray;
    font-size: 12px;
}

.pretorForm{
  min-height: 36px;
  border-radius: 5px;
  border: 1px solid rgb(222, 222, 223);
  padding-left: 10px;
  outline: none;
  width: 100%;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .pretorForm {
    min-height: 40px;
  }

  .pretorButton {
    min-height: 40px;
  }

  body { 
    font-size: 14px !important;
  }

  .pretorCard .title {
    font-size: 16px !important;
  }
}

.invalid{
  border: 1px solid rgb(237, 194, 188) !important;
  background-color: rgb(255, 246, 246) !important;
  color: rgb(224, 180, 180) !important;
}

.pretorForm::placeholder{
  color: rgb(178, 179, 179);
}

.invalid::placeholder{
  color: rgb(224, 180, 180)
}

.pretorModal{
  z-index: 9999 !important;
}

.pretorModal label{
  font-size: 12px;
  color: gray;
}

.pretorModal .pretorForm{
  margin-bottom: 15px;
}

.pretorModal .modal-header{
  padding-bottom: 10px;
}

/* Estilos generales de la scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la scrollbar */
  background-color: #F0F0F0; /* Color de fondo de la scrollbar */
}

/* Estilo del thumb (barra deslizante) de la scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #C0C0C0; /* Color del thumb */
  border-radius: 4px; /* Radio de borde del thumb */
}

/* Estilo del thumb al pasar el mouse sobre la scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: #A0A0A0; /* Color del thumb al pasar el mouse */
}

/* Estilo del thumb cuando está presionado */
::-webkit-scrollbar-thumb:active {
  background-color: #808080; /* Color del thumb al estar presionado */
}

/* Estilo de la scrollbar en su estado inactivo */
::-webkit-scrollbar-track {
  background-color: #F0F0F0; /* Color de fondo del track (área no ocupada por el thumb) */
}

/* Estilo de la scrollbar inactiva */
::-webkit-scrollbar-track:no-button:no-thumb {
  background-color: rgb(193, 193, 193); /* Color de la scrollbar inactiva */
}
</style>