import { useToast } from 'vue-toastification';

const toast = useToast();

const toastOptions = {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 1,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
} 

const ShowNotification = {
    Success : (message) => {
        toast.success(message, toastOptions);
    },
    Error : (message) => {
        toast.error(message, toastOptions);
    },
    Warning : (message) => {
        toast.warning(message, toastOptions);
    }
}

export default ShowNotification
