import RequestSender from './RequestSender.js';

function CreateFile(file) {
    return RequestSender.Post('file/AddFile', file);
}

function GetAllFiles(){
    return RequestSender.Get('file/GetAllFiles');
}

function AddFileDocuments(data){
    return RequestSender.Post('file/AddFileDocuments', data);
}

function AddFileDocumentsFormData(data, files){
    return RequestSender.PostFormData('file/AddFileDocuments', data, files);
}

function GetFileDetail(data){
    return RequestSender.Get('file/GetFileDetail', data);
}

function GetOriginalDocument(data) {
    return RequestSender.Get('file/GetOriginalDocument', data);
}

function UpdateFileDocumentPosition(data){
    return RequestSender.Post('file/UpdateFileDocumentPosition', data);
}

function GetFileDocumentDetail(data){
    return RequestSender.Get('file/GetFileDocumentDetail', data);
}

function UpdateUnpinnedFile(data){
    return RequestSender.Post('file/UpdateUnpinnedFile', data);
}

function GetAvailableBusinesses() {
    return RequestSender.Get('file/GetAvailableBusinesses');
}

function GetAvailableJudges() {
    return RequestSender.Get('file/GetAvailableJudges');
}

function GetAvailableCourts() {
    return RequestSender.Get('file/GetAvailableCourts');
}

function GetAvailableSecretaries() {
    return RequestSender.Get('file/GetAvailableSecretaries');
}

function UpdateFile(file) {
    return RequestSender.Post('file/UpdateFile', file);
}

function AddRelatedFile(data){
    return RequestSender.Post('file/AddRelatedFile', data);
}

function RemoveRelatedFile(data){
    return RequestSender.Post('file/RemoveRelatedFile', data);
}

function UpdateFileDocument(data, files){
    return RequestSender.PostFormData('file/UpdateFileDocument', data, files);
}

function DisableFileDocument(data){
    return RequestSender.Post('file/DisableFileDocument', data);
}

function DisableFile(data){
    return RequestSender.Post('file/DisableFile', data);
}

function GetFileTasks(data){
    return RequestSender.Get('file/GetFileTasks', data);
}

function GetFileRelatedFiles(data){
    return RequestSender.Get('file/GetFileRelatedFiles', data);
}

function CloseFile(data){
    return RequestSender.Post('file/CloseFile', data);
}

function OpenFile(data){
    return RequestSender.Post('file/OpenFile', data);
}

function GetAvailableFilesForDropdowns(){
    return RequestSender.Get('file/GetAvailableFilesForDropdowns');
}

function GetFileParts(data){
    return RequestSender.Get('file/GetFileParts', data)
}

function GetUserUnpinnedFiles(){
    return RequestSender.Get('file/GetUserUnpinnedFiles');
}

function ValidateCustomerFileAccess(data){
    return RequestSender.Get('file/ValidateCustomerFileAccess', data);
}

function SetUserLastFileSeen(data){
    return RequestSender.Post('file/SetUserLastFileSeen', data);
}

export default {
    CreateFile, GetAllFiles, AddFileDocuments, GetFileDetail, GetOriginalDocument, UpdateFileDocumentPosition, GetFileDocumentDetail, UpdateUnpinnedFile,
    GetAvailableBusinesses, GetAvailableJudges, GetAvailableCourts, GetAvailableSecretaries, UpdateFile, AddRelatedFile, RemoveRelatedFile, UpdateFileDocument,
    DisableFileDocument, DisableFile, GetFileTasks, GetFileRelatedFiles, CloseFile, OpenFile, GetAvailableFilesForDropdowns,
    GetFileParts, GetUserUnpinnedFiles, ValidateCustomerFileAccess, AddFileDocumentsFormData, SetUserLastFileSeen
}