import RequestSender from './RequestSender.js';

import { useStore } from '@/store/DefaultStore.js';
import PromiseCallerService from '@/services/PromiseCallerService.js';
import UserService from '@/services/UserService.js';
import RoleService from '@/services/RoleService.js';
import FileService from '@/services/FilesService.js';
import SessionHandler from '@/authentication/SessionHandler.js';

function GetAvailableUsers() {
    return RequestSender.Get('user/GetAvailableUsers');
}

function GetCurrentUserData(){
    return RequestSender.Get('user/GetCurrentUserData');
}

function GetAllRoles() {
    return RequestSender.Get('user/GetAllRoles');
}

function GetUsersMessageData(data){
    return RequestSender.Get('user/GetUsersMessageData', data);
}

function GetUserDetail(PKUser){
    return RequestSender.Get('user/GetUserDetail', PKUser);
}

function RegisterUser(user){
    return RequestSender.Post('user/RegisterUser', user);
}

function EditUser(user){
    return RequestSender.Post('user/EditUser', user);
}

function AddSecretKey(secret){
    return RequestSender.Post('user/AddSecretKey', secret);
}

function DisableUser(data){
    return RequestSender.Post('user/DisableUser', data);
}

function EnableUser(data){
    return RequestSender.Post('user/EnableUser', data);
}

function AddUserToOrganization(data){
    return RequestSender.Post('user/AddUserToOrganization', data);
}

function ResendInvitation(data){
    return RequestSender.Post('user/ResendInvitation', data);
}

function CancelInvitation(data){
    return RequestSender.Post('user/CancelInvitation', data);
}

function DeleteUserProfilePicture(){
    return RequestSender.Post('user/DeleteUserProfilePicture');
}

function UpdateUserProfilePicture(data){
    return RequestSender.PostFormData('user/UpdateUserProfilePicture', {}, data);
}

function GetUserOrganizations(){
    return RequestSender.Get('user/GetUserOrganizations');
}

function GetUserRoleByOrganization(data){
    return RequestSender.Get('user/GetUserRoleByOrganization', data);
}

function GetUserExistsByEmail(data){
    return RequestSender.Get('user/GetUserExistsByEmail', data);
}

function GetAllUsers(){
    return RequestSender.Get('user/GetAllUsers');
}

function GetSuperadmins(){
    return RequestSender.Get('user/GetSuperadmins');
}

function AddSuperadmin(data){
    return RequestSender.Post('user/AddSuperadmin', data);
}

function ChangeSuperadminStatus(data){
    return RequestSender.Post('user/ChangeSuperadminStatus', data);

}

async function SetUserInitialData() {
    const store = useStore();
  
    try
    {
      store.SetLoader(true);
      let user, organizations, role, lastOrganization;
  
      await PromiseCallerService.CallMethodAsPromise(UserService.GetCurrentUserData)
      .then(data => {
        user = data.User;
      })
      .catch(error => {
        throw new Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LA INFORMACIÓN DEL USUARIO');
      });
  
      await PromiseCallerService.CallMethodAsPromise(UserService.GetUserOrganizations)
      .then(data => {
        organizations = data.Organizations;
  
        if(organizations.length == 0){
          throw new Error('EL USUARIO NO TIENE ORGANIZACIONES ASOCIADAS');
        }
  
        store.SetOrganizations(organizations);
        lastOrganization = SessionHandler.GetCurrentOrganization();
  
        if(lastOrganization != null && organizations.some(x => x.PKOrganization === lastOrganization)){
          store.CurrentOrganizationPK = lastOrganization;
        }
        else{
          lastOrganization = organizations[0].PKOrganization;
          store.CurrentOrganizationPK = lastOrganization;
          SessionHandler.SetCurrentOrganization(lastOrganization);
        }
      })
      .catch(error => {
        throw new Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LAS ORGANIZACIONES DEL USUARIO');
      })
  
      await PromiseCallerService.CallMethodAsPromise(UserService.GetUserRoleByOrganization, {PKOrganization: lastOrganization})
      .then(data => {
        role = data.Role;
        user.Role = role;
      })
      .catch(error => {
        throw new Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR EL ROL DEL USUARIO');
      })
  
      await PromiseCallerService.CallMethodAsPromise(RoleService.GetUserActions)
      .then(data => {
        store.SetUserActions(data.Actions);
      })
      .catch(error => {
        throw new Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LAS ACCIONES DEL USUARIO');
      })
  
      await PromiseCallerService.CallMethodAsPromise(FileService.GetUserUnpinnedFiles)
      .then(data => {
        store.unpinnedFiles = data.UnpinnedFilesPKs;
        store.SetLoggedUser(user);
      })
      .catch(error => {
        throw new Error(error.message ? error.message : 'OCURRIÓ UN ERROR AL CARGAR LOS EXPEDIENTES FIJADOS');
      })
    }
    catch(error){
      store.SetLoader(false);
      throw error;
    }
  }

export default {
    GetAvailableUsers, GetCurrentUserData, GetAllRoles, DisableUser, EnableUser, GetUsersMessageData, GetUserDetail, RegisterUser, EditUser, AddSecretKey,
    AddUserToOrganization, ResendInvitation, CancelInvitation, DeleteUserProfilePicture, UpdateUserProfilePicture, GetUserOrganizations,
    GetUserRoleByOrganization, GetUserExistsByEmail, GetAllUsers, GetSuperadmins, AddSuperadmin, ChangeSuperadminStatus, SetUserInitialData
}