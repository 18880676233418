import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

//QUITAR
// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
//   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_FIREBASE_APP_ID
// };

//PRD
const firebaseConfig = {
  apiKey: "AIzaSyDq3rFOFQYD9V3leNkf7lfkCler5SRBEy4",
  authDomain: "pretorapp-295e1.firebaseapp.com",
  projectId: "pretorapp-295e1",
  storageBucket: "pretorapp-295e1.appspot.com",
  messagingSenderId: "755732685478",
  appId: "1:755732685478:web:bf2276d1f0bd873635ca67",
  measurementId: "G-M759SND0XY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export const db = getFirestore(app);

export default { app, auth };