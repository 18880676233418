<template>
    <div class="notificationCard" @click="ClickHandler">
        <div class="profilePictureWrapper">
                        
            <img v-if="userData.ProfilePictureURL"
                :src="userData.ProfilePictureURL"
                class="profilePicture">

            <div v-else class="profilePicture initials">
                <label>{{ userData.Initials }}</label>
            </div>

            <div class="notificationIcon">
                <i :class="notification.IconName"></i>
            </div>
        </div>

        <div class="notificationInfo">
            <div>
                <b>EXP. {{ notification.FileNumber }}</b>
            </div>

            <div class="notificationMessage">
                {{ notification.User + " " + notification.DisplayMessage }}
            </div>

            <div class="notificationDate">
                {{ notification.Date }}
            </div>
        </div>
        <div v-if="!notification.IsRead" class="notReadNotificationPoint">
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationCard",
    props: {
        notification: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>
.notificationMessage{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Número de líneas antes de truncar */
    overflow: hidden;
    text-overflow: ellipsis;
}

.notificationDate {
    color: gray;
}

.profilePicture {
    width: 54px; height: 54px; box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3); border-radius: 50%;
}

.profilePicture.initials {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--LativoLightPurple);
    color: var(--LativoDarkPurple);
    font-size: 22px !important;
    font-weight: bold;
}

.notificationCard{
    position: relative;
    width: 100%;
    color: white;
    display: flex;
    padding: 10px;
    padding-left: 20px;
    cursor: pointer;
    max-height: auto !important;
    height: auto !important;
    align-items: center;
}

.notificationCard:hover {
    background-color: var(--LativoDarkPurpleHover);
}

.notReadNotificationPoint {
    max-height: auto; display: block; margin-left:auto; background-color: var(--Notification); border-radius: 50%; min-width: 12px; min-height: 12px; margin-right: 10px;
}

.notificationIcon {
    position: absolute; bottom: 0; right: 0; background-color: var(--Notification); display: inline-block; border-radius: 50%; width: 24px; height: 24px;
}

.notificationIcon i {
    margin-right: 0 !important; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 14px;
}

.profilePictureWrapper {
    position: relative; width: 54px; margin-right: 20px; display: flex;
}

.notificationInfo {
    max-height: auto; margin-right: 20px;
}

.bi.bi-pencil, .bi.bi-x-lg, .bi.bi-unlock-fill {
    font-size: 12px;
}
</style>