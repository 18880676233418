import RequestSender from './RequestSender.js';

function GetUserScreens() {
    return RequestSender.Get('role/GetUserScreens');
}

function GetUserActions() {
    return RequestSender.Get('role/GetUserActions');
}

export default {
    GetUserScreens, GetUserActions
}