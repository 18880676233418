<template>
    <ConfirmationModal ref="confirmationModal" v-if="!store.isCustomer"/>

    <button ref="openModal" hidden data-bs-toggle="modal" :data-bs-target="'#' + modalId"></button>

    <button ref="imageModalButton" hidden data-bs-toggle="modal" data-bs-target="#imageModal"></button>
    <div ref="imageModalRef" class="modal fade" id="imageModal" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <b>IMAGEN DE LA ORGANIZACIÓN</b>
                    <button ref="closeImageModalButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="CloseImageModal"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <i class="bi bi-info-circle"></i>
                        Puedes ajustar el tamaño y posición de la imagen tomando el marco circular como referencia de la vista que tendrá en la plataforma
                    </div>

                    <Cropper ref="cropper" :id="modalId + '_cropper'"/>
                </div>
                <div class="modal-footer">
                    <PretorButton
                        :style="'width: 100%; margin-top: 10px;'"
                        :icon="'bi bi-upload'"
                        :label="'CAMBIAR IMAGEN'"
                        @click="OpenFilePicker"/>

                    <PretorButton
                        :style="'width: 100%; margin-top: 10px;'"
                        :icon="'bi bi-arrow-right-circle'"
                        :label="'CONTINUAR'"
                        @click="SelectImage"/>
                </div>
            </div>
        </div>
    </div>


    <div ref="organizationModalRef" class="modal fade" :id="modalId" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable pretorModal">
            <div class="modal-content">
                <div class="modal-header" style="color:gray">
                    <b>
                        {{ create ? 'REGISTRANDO ORGANIZACIÓN' : view ? 'DETALLES DE LA ORGANIZACIÓN': 'EDITANDO ORGANIZACIÓN'}}
                    </b>
                    <button ref="btnCloseorganizationModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <!--CREATE OR EDIT AGREEMENT-->
                <div v-if="create || edit" class="modal-body" style="padding-top: 10px;">
                    <div class="mandatoryFields">
                        <label class="required requiredFields">*</label>
                        <label>CAMPOS OBLIGATORIOS</label>
                    </div>

                    <div class="organizationImage">
                        <div class="header">
                            <label>IMAGEN REPRESENTATIVA</label>

                            <button v-if="Organization.ImageURL" class="iconButton" title="ELIMINAR IMAGEN REPRESENTATIVA" @click="DeleteOrganizationImage">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </div>

                        <input ref="fileInput" type="file" accept="image/*" hidden @change="HandleFileChange">

                        <div
                            style="display: flex; justify-content: center; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">

                            <div class="profilePictureContainer" @click="OpenFilePicker">

                                <img v-if="image == null && !Organization.ImageURL" src="../../../images/OrganizationIcon.png" class="profilePicture">
                                <img v-if="image == null && Organization.ImageURL" :src="Organization.ImageURL" class="profilePicture">
                                <img v-if="image != null" :src="image" class="profilePicture">

                                <i class="bi bi-pencil icon"></i>
                            </div>

                        </div>
                    </div>
                    
                    <PretorInput ref="txtOrganization" 
                        :label="'NOMBRE DE LA ORGANIZACIÓN'" 
                        :placeholder="'NOMBRE DE LA ORGANIZACIÓN'" 
                        :required="true"
                        :validate="/^[\w&?() -.:;ÑÓÍÁÚÉÜ]+$/"
                        :validateMessage="'EL NOMBRE DE LA ORGANIZACIÓN PUEDE ESTAR COMPUESTO SOLAMENTE POR LETRAS, NUMEROS, ESPACIOS Y LOS SIGUIENTES CARÁCTERES: &, ?, #, -, (, ), ., ;, :'" />

                        <!-- Campos solo disponibles para suberadministradores  -->
                    <div v-if="!notSuperadminMode">
                        <PretorInput ref="txtPhone" 
                            :label="'NÚMERO DE TELÉFONO'" 
                            :placeholder="'NÚMERO DE TELÉFONO'" 
                            :required="false"
                            :validate="/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/"
                            :validateMessage="'EL NÚMERO DE TELÉFONO NO ES VÁLIDO'" />

                        <PretorInput ref="txtEmail" 
                            :label="'CORREO ELECTRÓNICO'" 
                            :placeholder="'CORREO ELECTRÓNICO'" 
                            :required="false"
                            :validate="/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/"
                            :validateMessage="'EL CORREO ELECTRÓNICO NO ES VÁLIDO'"/>

                        <PretorInput ref="txtAddress" 
                            :label="'DOMICILIO'" 
                            :placeholder="'DOMICILIO'" 
                            :required="false"
                            :validate="/^[\w&?() -.:;ÑÓÍÁÚÉÜ]+$/"
                            :validateMessage="'EL DOMICILIO DE LA ORGANIZACIÓN PUEDE ESTAR COMPUESTO POR LETRAS, NUMEROS, ESPACIOS Y LOS SIGUIENTES CARÁCTERES: &, ?, #, -, (, ), ., ;, :'"/>

                        <PretorInput ref="txtRFC" 
                            :label="'RFC'" 
                            :placeholder="'RFC'" 
                            :required="false"
                            :validate="/^[\w\dñÑ]{12,}$/"
                            :validateMessage="'EL RFC DE LA ORGANIZACIÓN PUEDE ESTAR COMPUESTO SOLAMENTE POR LETRAS Y NÚMEROS, DEBE TENER AL MENOS 12 CARACTERES '"/>

                        <PretorInput ref="txtAdminEmail" 
                            :label="'CORREO ELECTRÓNICO DEL ADMINISTRADOR'" 
                            :placeholder="'CORREO ELECTRÓNICO DEL ADMINISTRADOR'" 
                            :required="true"
                            :validate="/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/"
                            :disabled="edit && Organization.CanEditAdmin == 0"/>
                    </div>
                </div>

                <div v-else class="modal-body viewState" style="padding-top: 10px; font-size: 16px;">

                    <div style="display: flex; justify-content: center; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
                        <img v-if="Organization.ImageURL" :src="Organization.ImageURL" class="profilePicture">
                        <img v-else src="../../../images/OrganizationIcon.png" class="profilePicture">
                    </div>

                    <div><b>NOMBRE</b> {{ Organization.Organization }}</div>
                    <div><b>ADMIN</b> {{ Organization.AdminEmail }}</div>
                    <div><b>STATUS</b> {{ Organization.Status }}</div>

                    <div v-if="Organization.Email != null"><b>EMAIL</b> {{ Organization.Email }}</div>
                    <div v-if="Organization.Address != null"><b>DIRECCIÓN</b> {{ Organization.Address }}</div>
                    <div v-if="Organization.PhoneNumber != null"><b>TELÉFONO</b> {{ Organization.PhoneNumber }}</div>
                    <div v-if="Organization.RFC != null"><b>RFC</b> {{ Organization.RFC }}</div>

                    <div><b>CREADA POR</b> {{ Organization.CreatorUser }} EL {{ Organization.CreationDate }}</div>
                  
                    <div v-if="Organization.CreationDate != Organization.LastUpdate">
                        <b>ACTUALIZADO POR</b> {{ Organization.UdaterUser }} EL {{ Organization.LastUpdate }}
                    </div>
                </div>
                
                <div class="modal-footer" v-if="create">
                    <PretorButton
                        :icon="'bi bi-plus-circle'" 
                        :label="'REGISTRAR ORGANIZACIÓN'"
                        @click="AddOrganizationCaller"/>
                </div>

                <div class="modal-footer" v-if="view">
                    <PretorButton v-if="Organization.CanEnable == 1"
                        :icon="'bi bi-unlock-fill'"
                        :label="'HABILITAR'"
                        @click="EnableOrganizationCaller"/>

                    <PretorButton v-if="Organization.CanDisable == 1"
                        :icon="'bi bi-lock-fill'"
                        :label="'DESHABILITAR'"
                        @click="DisableOrganizationCaller"/>

                    <PretorButton v-if="Organization.CanResendInvitation == 1"
                        :icon="'bi bi-send'"
                        :label="'REENVIAR INVITACIÓN'"
                        @click="ResendInvitationCaller"/>

                    <PretorButton v-if="Organization.CanEdit == 1"
                        :icon="'bi bi-pencil-square'"
                        :label="'EDITAR'"
                        @click="EditOrganization"/>
                </div>

                <div class="modal-footer" v-if="edit">
                    <PretorButton
                        :icon="'bi bi-x-circle'" 
                        :label="'CANCELAR'"
                        @click="CancelEditCaller"/>

                    <PretorButton
                        :icon="'bi bi-plus-circle'" 
                        :label="'GUARDAR CAMBIOS'"
                        @click="SaveChangesCaller"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PretorButton from '../../Generic/Button.vue'
import PretorInput from '../../Generic/Input.vue'
import { useStore } from '@/store/DefaultStore.js';
import Cropper from '@/components/Profile/Cropper.vue';
import { inject } from 'vue';
import UserService from '@/services/UserService.js';
import ConfirmationModal from '@/components/Generic/ConfirmationModal.vue';
import OrganizationService from '@/services/OrganizationService';
import SessionHandler from '@/authentication/SessionHandler';

export default{
    name: 'DispatchModal',
    components: {
        PretorButton, PretorInput, Cropper, ConfirmationModal
    },
    props: {
        modalId: {
            type: String,
            required: false,
            default: 'organizationModal'
        }
    },
    setup(){
        const store = useStore();
        const ReadBase64File = inject('ReadBase64File');
        const ShowNotification = inject('ShowNotification');

        return{
            store, ReadBase64File, ShowNotification
        }
    },
    data(){
        return{
            notSuperadminMode: false,
            PKOrganization: null,
            create: true,
            edit: false,
            view: false,
            image: null,
            imageFile: null,
            selectedFile: null,
            fields: ['txtOrganization', 'txtPhone', 'txtEmail', 'txtAddress', 'txtRFC', 'txtAdminEmail'],
            fieldsProperties: {
                "txtOrganization" : 'Organization',
                "txtPhone" : 'PhoneNumber',
                "txtEmail" : 'Email',
                "txtAddress" : 'Address',
                "txtRFC" : 'RFC',
                "txtAdminEmail" : 'AdminEmail'
            },
            isOnCropper: false,
            organizationImageChanged: false,
            canSaveChanges: false,
            Organization: {},
            setMode: {
                create: () => {
                    this.create = true;
                    this.view = false;
                    this.edit = false;
                },
                view: () => {
                    this.create = false;
                    this.view = true;
                    this.edit = false;
                },
                edit: () => {
                    this.create = false;
                    this.view = false;
                    this.edit = true;
                }
            }
        }
    },

    mounted() {
        this.emitter.on('OpenOrganizationModalFromNavbar', () => {
            this.notSuperadminMode = true;
            this.OpenModal(this.store.CurrentOrganizationPK, true);
        });
    },

    beforeUnmount() {
        if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
        }

        this.emitter.off('OpenOrganizationModalFromNavbar');
    },

    methods: {
        DeleteOrganizationImage(){
            this.image = null;
            this.imageFile = null;
            this.Organization.ImageURL = null;
            this.organizationImageChanged = true;
        },

        ResendInvitation(){
            this.store.SetLoader(true);

            return new Promise((resolve, reject) => {
                UserService.ResendInvitation({
                    PKOrganization: this.PKOrganization,
                    PKUser: this.Organization.AdminPKUser
                })
                .then(data => {
                    this.store.SetLoader(false);
                    this.$refs.btnCloseorganizationModal.click();
                    this.$parent.GetAllOrganizations();

                    this.ShowNotification('success', data.message ? data.message : 'LA INVITACIÓN HA SIDO REENVIADA EXITOSAMENTE');
                    resolve();
                })
                .catch(error => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL REENVIAR LA INVITACIÓN');
                    reject(error);
                })

            });
        },

        ResendInvitationCaller(){
            if(this.Organization.InvitationExpired){
                let message = `Al continuar,  se reenviará una invitación de registro al correo <b>${this.Organization.AdminEmail}</b>  
                para ser admnistrador de la misma, una vez que el usuario se haya registrado <b>no podrá</b> ser cambiado por usted en el futuro, ¿desea continuar?`

                this.$refs.confirmationModal.OpenModal(
                    message,
                    false,
                    this.ResendInvitation,
                    null
                );
            }
            else{
                this.ResendInvitation();
            }
        },
        
        EnableOrganizationCaller(){
            let message = this.Organization.CanEditAdmin ? `Al habilitar esta organización el usuario administrador volverá a tener acceso al registro, 
                una vez que el usuario se haya registrado <b>no podrá</b> ser cambiado por usted en el futuro, ¿desea continuar?`
                : `Al habilitar esta organización los usuarios registrados a ella volverán a tener acceso a la misma, ¿desea continuar?`;

            this.$refs.confirmationModal.OpenModal(
                message,
                false,
                this.EnableOrganizaiton,
                null
            );
        },

        EnableOrganizaiton(){
            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);
                OrganizationService.EnableOrganization({PKOrganization: this.PKOrganization})
                .then(() => {
                    this.store.SetLoader(false);
                    this.$refs.btnCloseorganizationModal.click();
                    this.$parent.GetAllOrganizations();
                    this.ShowNotification('success', 'LA ORGANIZACIÓN HA SIDO HABILITADA EXITOSAMENTE');
                    resolve();
                })
                .catch(error => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL HABILITAR LA ORGANIZACIÓN');
                    reject(error);
                })

            });
        },

        DisableOrganizationCaller(){
            let message = this.Organization.CanEditAdmin ? `Al deshabilitar esta organización la invitación del usuario será cancelada ¿desea continuar?`
                : `Al deshabilitar esta organización todos los usuarios registrados a ella perderán acceso a la misma, ¿desea continuar?`;

            let logOutMsg = Object.keys(this.store.Organizations).length == 1 ? 'se cerrará tu sesión activa' : 'serás dirigido a tu siguiente organización activa';

            if(this.PKOrganization == this.store.CurrentOrganizationPK)
                message = `Estás deshabilitando la organización en la que te encuentras trabajando, 
                al deshabilitarla todos los usuarios registrados a ella perderán acceso a la misma y ${logOutMsg}, ¿desea continuar?`

            this.$refs.confirmationModal.OpenModal(
                message,
                false,
                this.DisableOrganizaiton,
                null
            );
        },

        DisableOrganizaiton(){
            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);
                OrganizationService.DisableOrganization({PKOrganization: this.PKOrganization})
                .then(() => {
                    this.store.SetLoader(false);
                    this.$refs.btnCloseorganizationModal.click();
                    this.$parent.GetAllOrganizations();
                    this.ShowNotification('success', 'LA ORGANIZACIÓN HA SIDO DESHABILITADA EXITOSAMENTE');

                    if(this.PKOrganization == this.store.CurrentOrganizationPK){
                        SessionHandler.LogOut();
                        this.$router.push({name: 'login'});
                    }

                    resolve();
                })
                .catch(error => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL DESHABILITAR LA ORGANIZACIÓN');
                    reject(error);
                })

            });
        },

        CancelEditCaller(){
            let message = `Al cancelar la edición de la organización se perderán los cambios realizados, ¿desea continuar?`;

            this.$refs.confirmationModal.OpenModal(
                message,
                false,
                this.CancelEdit,
                null
            );
        },

        CancelEdit(){
            return new Promise((resolve) => {
                if(this.notSuperadminMode){
                    this.$refs.btnCloseorganizationModal.click();
                    resolve();
                    return;
                }

                this.setMode.view();
                resolve();
            });
        },

        SaveChanges(){
            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);

                let organization = this.GetOrganizationModel();

                let formData = new FormData();
                if(this.organizationImageChanged){
                    formData.append('Image', this.imageFile);
                }

                OrganizationService.EditOrganization(organization, formData)
                .then(() => {
                    this.store.SetLoader(false);
                    this.$refs.btnCloseorganizationModal.click();

                    if(!this.notSuperadminMode){
                        this.$parent.GetAllOrganizations();
                    }
                    
                    if(this.PKOrganization == this.store.CurrentOrganizationPK)
                        this.ReloadCurrentOrganizationData();

                    this.ShowNotification('success', 'LA ORGANIZACIÓN HA SIDO ACTUALIZADA EXITOSAMENTE');

                    resolve();
                })
                .catch(error => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL ACTUALIZAR LA ORGANIZACIÓN');
                    reject(error);
                })

            })
        },

        ReloadCurrentOrganizationData()
        {
            this.store.SetLoader(true);

            OrganizationService.GetOrganizationDetail({ PKOrganization: this.PKOrganization })
            .then(data => {
                let currentOrganization = this.store.Organizations.find(x => x.PKOrganization == this.PKOrganization);
                currentOrganization.ImageURL = data.Organization.ImageURL;
                currentOrganization.Organization = data.Organization.Organization;

                this.store.SetLoader(false);
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL OBTENER LOS DETALLES DE LA ORGANIZACIÓN');
                this.store.SetLoader(false);
            })
        },

        SaveChangesCaller(){
            this.store.SetLoader(true);

            if(!this.ValidateForm()){
                this.store.SetLoader(false);
                this.ShowNotification('error', 'ES NECESARIO LLENAR LOS CAMPOS OBLIGATORIOS');
                return;
            }

            if(!this.GetCanSaveChanges()){
                this.store.SetLoader(false);
                this.ShowNotification('error', 'NO SE SE HAN HECHO CAMBIOS A GUARDAR');
                return;
            }

            this.store.SetLoader(false);

            if(this.notSuperadminMode){
                this.SaveChanges();
                return;
            }

            if(this.Organization.CanEditAdmin && this.$refs.txtAdminEmail.GetValue() != this.Organization.AdminEmail.toUpperCase()){
                let message = `Al cambiar el correo eléctronico del administrador, la invitación del usuario administrador original será cancelada y se enviará una invitación al del
                nuevo administrador, una vez que el usuario se haya registrado <b>no podrá</b> ser cambiado por usted en el futuro, ¿desea continuar?`;

                this.$refs.confirmationModal.OpenModal(
                    message,
                    false,
                    this.SaveChanges,
                    null
                );
            }
            else{
                this.SaveChanges()
                .catch(() => {});
            }
        },

        GetCanSaveChanges(){
            let canSaveChanges = false;

            let fields = this.fields;
            if(this.notSuperadminMode)
                fields = ['txtOrganization'];

            fields.forEach(field => {
                if(this.$refs[field].GetValue() != (this.Organization[this.fieldsProperties[field]] == null ? '' : this.Organization[this.fieldsProperties[field]]).toUpperCase()){
                    canSaveChanges = true;
                    return;
                }
            });

            return canSaveChanges || this.organizationImageChanged;
        },

        EditOrganization(){
            this.setMode.edit();

            this.$nextTick(() => {
                this.ClearModal();
                this.SetOrganizationData();
            });
        },

        SetOrganizationData(){
            this.$refs.txtOrganization.SetValue(this.Organization.Organization);

            if(this.notSuperadminMode)
                return;

            this.$refs.txtPhone.SetValue(this.Organization.PhoneNumber ?? '');
            this.$refs.txtEmail.SetValue(this.Organization.Email ?? '');
            this.$refs.txtAddress.SetValue(this.Organization.Address ?? '');
            this.$refs.txtRFC.SetValue(this.Organization.RFC ?? '');
            this.$refs.txtAdminEmail.SetValue(this.Organization.AdminEmail);
        },

        async OpenModal(PKOrganization = null, fromProfileMenu = false)
        {
            this.notSuperadminMode = fromProfileMenu
            this.organizationImageChanged = false;

            if(PKOrganization == null){
                this.Organization = {};
                //clear the modal and open it
                this.setMode.create();

                this.$nextTick(() => {
                    this.ClearModal();
                });

                setTimeout(() => {
                    if(this.$refs.txtOrganization)
                        this.$refs.txtOrganization.SetFocus();
                }, 500)
            }
            else{
                //get the info of the organizaiton
                this.PKOrganization = PKOrganization;
                await this.GetOrganizationDetail();

                if(this.notSuperadminMode)
                    this.EditOrganization();
                else
                    this.setMode.view();
            }

            this.$refs.openModal.click();
        },

        CloseImageModal(){
            this.isOnCropper = false;
            this.$refs.fileInput.value = '';
            this.$refs.openModal.click();
        },

        OpenFilePicker() {
            this.$refs.fileInput.click();
        },

        HandleFileChange() {
            let file = this.$refs.fileInput.files[0];

            if(this.selectedFile != null && file == null)
                return;

            if(file.type != 'image/png' && file.type != 'image/jpeg'){
                this.ShowNotification('error', 'EL ARCHIVO SELECCIONADO NO ES UNA IMAGEN VÁLIDA');
                return;
            }

            this.selectedFile = file ? file : null;

            if(this.selectedFile == null)
                return;

            if(!this.isOnCropper){
                this.$refs.imageModalButton.click();
                this.isOnCropper = true;
            }

            this.$nextTick(() => {
                this.$refs.cropper.uploadImage(this.selectedFile);
            })
        },

        async SelectImage(){
            this.imageFile = await this.$refs.cropper.getCroppedImage();
            this.image = URL.createObjectURL(this.imageFile);
            this.organizationImageChanged = true;
            this.CloseImageModal();
        },

        CheckAdminExists(){
            return new Promise((resolve, reject) => {

                let Email = this.$refs.txtAdminEmail.GetValue();

                UserService.GetUserExistsByEmail({Email})
                .then(data => {
                    resolve(data.Exists);
                })
                .catch(error => {
                    reject(error);
                })
            })
        },

        ValidateForm() {
            let valids = [];

            let fields = this.fields;
            if(this.notSuperadminMode)
                fields = ['txtOrganization'];

            fields.forEach(field => {
                if (this.$refs[field])
                    valids.push(this.$refs[field].GetValid());
            });

            return (valids.includes(false) ? false : true);
        },

        async AddOrganizationCaller(){
            if(!this.ValidateForm()){
                this.ShowNotification('error', 'ES NECESARIO LLENAR LOS CAMPOS DE FORMA VÁLIDA');
                return;
            }

            this.store.SetLoader(true);
            let userExists;
            await this.CheckAdminExists()
            .then(data => {
                this.store.SetLoader(false);
                userExists = data;
            })
            .catch(error => {
                this.store.SetLoader(false);
                this.ShowNotification('error', error.message ? error.message : '');
            })

            let message = userExists ?
            `Al continuar, se creará una organización independiente al resto existente en la plataforma: El usuario con el correo <b>${this.$refs.txtAdminEmail.GetValue()}</b> 
            será asignado como el admnistrador de la misma y <b>no podrá</b> ser cambiado por usted en el futuro, ¿desea continuar?`
            : `Al continuar, se creará una organización independiente al resto de la plataforma: Se enviará una invitación de registro al correo <b>${this.$refs.txtAdminEmail.GetValue()}</b>  
            para ser admnistrador de la misma, una vez que el usuario se haya registrado <b>no podrá</b> ser cambiado por usted en el futuro, ¿desea continuar?`;

            this.$refs.confirmationModal.OpenModal(
                message,
                false,
                this.AddOrganization,
                null
            );
        },

        AddOrganization(){
            this.store.SetLoader(true);

            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);

                let organization = this.GetOrganizationModel();

                let formData = new FormData();
                if(this.organizationImageChanged){
                    formData.append('Image', this.imageFile);
                    organization.Image = formData;
                }

                OrganizationService.AddOrganization(organization, formData)
                .then(() => {
                    this.$refs.btnCloseorganizationModal.click();
                    this.$parent.GetAllOrganizations();
                    this.ShowNotification('success', 'ORGANIZACIÓN REGISTRADA CORRECTAMENTE');
                    
                    UserService.GetUserOrganizations()
                    .then(data => {
                        this.store.SetOrganizations(data.Organizations);
                    })
                    .catch(error => {
                        this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL OBTENER LAS ORGANIZACIONES DEL USUARIO');
                    });

                    this.store.SetLoader(false);
                    resolve();
                })
                .catch(error => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL REGISTRAR LA ORGANIZACIÓN');
                    reject(error);
                })
            })
        },

        GetOrganizationModel() {
            if(this.notSuperadminMode)
                return {
                    PKOrganization: this.PKOrganization,
                    Organization: this.$refs.txtOrganization.GetValue(),
                    ImageChanged: this.organizationImageChanged,
                    NotSuperadminMode: true
                }

            return {
                PKOrganization: this.PKOrganization,
                Organization: this.$refs.txtOrganization.GetValue(),
                Email: this.$refs.txtEmail.GetValue(),
                PhoneNumber: this.$refs.txtPhone.GetValue(),
                Address: this.$refs.txtAddress.GetValue(),
                RFC: this.$refs.txtRFC.GetValue(),
                AdminEmail: this.$refs.txtAdminEmail.GetValue(),
                ImageChanged: this.organizationImageChanged,
                NotSuperadminMode: false
            }
        },

        async GetOrganizationDetail() {
            this.store.SetLoader(true);

            await OrganizationService.GetOrganizationDetail({ PKOrganization: this.PKOrganization })
            .then(data => {
                this.Organization = data.Organization;
            })
            .catch(error => {
                this.ShowNotification('error', error.message ? error.message : 'OCURRIÓ UN ERROR AL REGISTRAR LA ORGANIZACIÓN');
            })

            this.store.SetLoader(false);
        },

        ClearModal(){
            this.fields.forEach(field => {
                if(this.$refs[field])
                    this.$refs[field].ClearField();
            });

            this.image = null;
            this.selectedFile = null;
        },

    }
}
</script>

<style scoped>
.mandatoryFields {
    width: 100%; display: flex; justify-content: right; margin-bottom: 5px;
}

.organizationImage {
    width: 100%;
}

.organizationImage .header {
    width: 100%; display: flex; margin-bottom: 15px;
}

.organizationImage .header label {
    margin-right: auto; margin-bottom: 10px;
}

.organizationImage .header .iconButton {
    margin-left: auto;
}

.organizationImage .header .iconButton {
    color: gray;
}

.modal-footer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Ajusta el ancho de los elementos */
    gap: 10px;
    align-items: stretch;
}
.viewState div b {
    margin-right: 5px;
}

.profilePictureContainer {
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
    border-radius: 50%;
}

.profilePictureContainer:hover .icon {
  opacity: 1;
}

.profilePictureContainer, .profilePictureContainer * {
  cursor: pointer;
}
.profilePicture {
  user-select: none;
  width: 100px;
  height: 100px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: var(--LativoLightPurple);
  color: var(--LativoDarkPurple);
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: filter 0.3s;
}

.profilePicture img.image {
  display: block;
  width: 100px;
  height: 100px;
}

.profilePicture .initials {
  display: none;
}

.profilePicture .initials label {
  font-size: 20px;
}

.profilePictureContainer:hover .profilePicture {
  filter: brightness(50%);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  opacity: 0; 
  transition: opacity 0.3s; 
}

</style>