<template>

    <div class="dropdown" style="max-width: 500px;">
        <button ref="userButtonRef" class="userButton" data-bs-toggle="dropdown" aria-expanded="false" style="margin-left: auto;" data-bs-auto-close="outside">
            <img v-if="store.Organizations.find(x => x.PKOrganization == store.CurrentOrganizationPK).ImageURL"
              :src="store.Organizations.find(x => x.PKOrganization == store.CurrentOrganizationPK).ImageURL">

            <img v-else src="../../images/OrganizationIcon.png">

            <div v-if="store.deviceWidth > 600">
              {{ store.LoggedUser?.Name }}
            </div>
        </button>

        <div ref="profileMenu" class="dropdown-menu profileMenu">
            <div style="display: flex; justify-content: center; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
                <!-- Profile picture / initials -->
                <div class="profilePictureContainer" @click="LoadProfileModal">
                    <img v-if="store.LoggedUser.ProfilePictureURL != null" :src="store.LoggedUser.ProfilePictureURL"
                        class="profilePicture">

                    <div v-else class="profilePicture"> 
                        <label>{{ store.LoggedUser.Initials }}</label>
                    </div>

                    <i class="bi bi-pencil icon"></i>
                </div>

                <!-- Settings button -->
                <button class="iconButton configButton" @click="GoToConfig">
                    <i class="bi bi-gear-wide-connected"></i>
                </button>
            </div>

            <div style="font-weight: bold">{{ store.LoggedUser.Name }}</div>
            <div class="email">{{ store.LoggedUser.Email }}</div>

            <div class="organizationList currentOrganization">
              <label>ORGANIZACIÓN ACTUAL</label>
              <div>
                <img v-if="store.Organizations.find(x => x.PKOrganization == store.CurrentOrganizationPK).ImageURL"
                  :src="store.Organizations.find(x => x.PKOrganization == store.CurrentOrganizationPK).ImageURL">

                <img v-else src="../../images/OrganizationIcon.png">

                <label>{{ store.Organizations.find(x => x.PKOrganization == store.CurrentOrganizationPK).Organization }}</label>

                <!-- Edit button -->
                <button v-if="store.LoggedUser.Role == 'ADMIN'"
                  class="iconButton configButton"
                  @click="EditOrganization">
                    <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>

            <div class="organizationList" v-if="store.Organizations.filter(x => x.PKOrganization != store.CurrentOrganizationPK).length > 0">
              <label>MIS ORGANIZACIONES</label>
              <div v-for="organization in store.Organizations.filter(x => x.PKOrganization != store.CurrentOrganizationPK)"
                class="organization"
                :key="organization.PKOrganization"
                @click="ChangeOrganization(organization.PKOrganization)">

                <img v-if="organization.ImageURL" :src="organization.ImageURL">
                <img v-else src="../../images/OrganizationIcon.png">

                <label style="cursor: pointer;">{{ organization.Organization }}</label>
              </div>
            </div>

            <div class="signOutButton" @click="logout">
                <i class="bi bi-box-arrow-left"></i>
                CERRAR SESIÓN
            </div>
        </div>
    </div>
</template>

<script>
import SessionHandler from '@/authentication/SessionHandler.js';
import { useStore } from '@/store/DefaultStore.js';
import { inject } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: 'ProfileMenu',

    data() {
        return {
            PKUser: false,
            MenuIsVisible: false
        }
    },

    watch: {
        $route(to){
            this.currentRoute = to.name;
        }
    },

    setup() {
        const store = useStore();
        const ShowNotification = inject('ShowNotification');
        const router = useRouter();
        const RefreshPage = inject('ChangeOrganization');

        return {
            store, ShowNotification, router, RefreshPage
        }
    },

    methods: {
        EditOrganization()
        {
          if(this.currentRoute != 'organizations'){
            this.$parent.EditOrganization();
          }
          else {
            this.emitter.emit("OpenOrganizationModalFromNavbar");
          }
        },

        GoToConfig()
        {
          this.router.push({ name: 'settings' });
          this.$refs.userButtonRef.click();
        },

        ChangeOrganization(PKOrganization) {
            if(PKOrganization == this.store.CurrentOrganizationPK)
              return;

            this.store.SetLoader(true);
            this.store.CurrentOrganizationPK = PKOrganization;
            this.store.organizationChanged = true;
            SessionHandler.SetCurrentOrganization(PKOrganization);
            this.RefreshPage();
        },

        LoadProfileModal() {
            this.$parent.OpenProfileModal();
        },

        logout() {
            SessionHandler.LogOut();
            this.store.SetLoggedUser(null);

            this.ShowNotification('success', 'SE HA CERRADO LA SESIÓN CORRECTAMENTE');
            this.router.push({ name: 'login' });
        },
    }
}
</script>

<style scoped>
@media screen and (min-width: 600px){
  .profileMenu {
    width: 400px;
  }

  .userButton img {
    margin-right: 5px;
  }
}
/* Mobile */
@media screen and (max-width: 600px){
  .profileMenu {
    width: 100vw;
  }

  .userButton {
    margin-right: 5px;
  }

  .userButton div {
    margin-left: 10px;
  }
}

.configButton i {
  font-size: 16px !important;
}

.email {
  color: lightgray;
}

.configButton:hover {
  background-color: var(--LativoDarkPurpleHover);
}

.configButton {
  position: absolute; right: 15px;
}

.configButton i {
  font-size: 14px;
  color: lightgray;
}

.signOutButton {
  width: 100%;
  padding: 5px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 1px 2px rgb(0, 0, 0, 0.2);
}

.signOutButton i {
  margin-right: 10px;
}

.organizationList .organization {
  border-radius: 10px;
}

.organizationList .organization:hover {
  background-color: var(--LativoDarkPurpleHover);
}

.signOutButton:hover {
  background-color: var(--LativoDarkPurpleHover);
}

.profileMenu {
  background-color: var(--LativoDarkPurple);
  padding: 15px;
  color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 3px rgb(0, 0, 0, 0.2);
  text-align: center;
}

.userButton{
  width: auto;
}
.userButton > img {
  width: 25px;
  height: 25px;
}

.organizationList{
  width: 100%;
  margin-top: 25px;
  text-align: left;
}

.organizationList > label {
  margin-left: 10px;
  font-size: 14px;
  color: lightgray;
}

.organizationList > div{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.organizationList > div > i{
  margin-left: auto;
  margin-right: 10px;
}

.organizationList > div > img {
  width: 36px;
  height: 36px;
  margin-right: 15px;
  border-radius: 50%;
}

.organizationList.currentOrganization > div {
  cursor: auto;
}

.profilePictureContainer {
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;
}

.profilePictureContainer:hover .icon {
  opacity: 1;
}

.profilePictureContainer, .profilePictureContainer * {
  cursor: pointer;
}
.profilePicture {
  user-select: none;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: var(--LativoLightPurple);
  color: var(--LativoDarkPurple);
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Necesario para posicionar el ícono correctamente */
  transition: filter 0.3s; /* Agrega una transición para suavizar el efecto de oscurecimiento */
}

.profilePicture img.image {
  display: block;
  width: 100%;
  height: 100%;
}

.profilePicture .initials {
  display: none;
}

.profilePicture .initials label {
  font-size: 20px;
}

.profilePictureContainer:hover .profilePicture {
  filter: brightness(50%);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  opacity: 0; 
  transition: opacity 0.3s; 
}

</style>