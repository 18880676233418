<template>
    <button class="pretorButton" :class="classObject" :disabled="disabled" :style="style">
        <i v-if="icon!=''" :class="icon" style="margin-right: 3px;"></i>
        {{ label }}
    </button>
</template>

<script>

export default{
    name: 'PretorButton',
    props:{
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        style: {
            type: String,
            required: false,
            default: ''
        },
        Class: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
        classObject() {
            const classes = {};

            if (this.Class !== '') {
                classes[this.Class] = true;
            }
            
            return classes;
        }
    },
}
</script>

<style>
.pretorButton{
    background-color: var(--LativoDarkPurple);
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: 0.3s;
    min-height: 36px;
    height: auto !important;
    max-height: max-content !important;
}

button:disabled {
  background-color: rgb(71, 71, 84);
}

.pretorButton.gray{
    background-color: rgb(220, 220, 220);
    color: black;
}

.pretorButton.gray:hover{
    background-color: rgb(200, 200, 200);
}

</style>