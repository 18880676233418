function CallMethodAsPromise(method, data) {
    return new Promise((resolve, reject) => {
        method(data)
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

export default {
    CallMethodAsPromise
}