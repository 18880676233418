import RequestSender from './RequestSender.js';

function ValidateInvitation(data) {
    return RequestSender.Get('authentication/ValidateInvitation', data);
}

function AddSecretKey(data){
    return RequestSender.Post('authentication/AddSecretKey', data);
}

function RemoveSecretKey(){
    return RequestSender.Post('authentication/RemoveSecretKey');
}

function GetUserSecretKey(){
    return RequestSender.Get('authentication/GetUserSecretKey');
}

function ValidateInvitationByEmail(data){
    return RequestSender.Get('authentication/ValidateInvitationByEmail', data);
}

function GetUserHasAccessToScreen(data){
    return RequestSender.Get('authentication/GetUserHasAccessToScreen', data);
}

export default {
    ValidateInvitation, AddSecretKey, RemoveSecretKey, GetUserSecretKey, ValidateInvitationByEmail, GetUserHasAccessToScreen
}